const accountLinkList = {
  toCss: "link-list-to-css",
  addLink: "add-link",
  deleteLink: "delete-link",
};

const main = { logout: "logout" };

const deleteLink = {
  cancel: "delete-link-cancel",
  delete: "delete-link-delete",
  confirmmessage: "delete-link-confirm",
  ok: "delete-link-ok",
};

const addLink = {
  cancel: "add-link-cancel",
  create: "add-link-create",
  customerId: "add-link-customer-id",
  contractId: "add-link-contract-id",
  firstname: "add-link-firstname",
  lastname: "add-link-lastname",
  confirmmessage: "add-link-confirm",
  ok: "add-link-ok",
};

export const contractConnect = {
  accountLinkList,
  main,
  deleteLink,
  addLink,
};
