import React, { useEffect, useState } from "react";
import { ThemeProvider } from "emotion-theming";
import styled from "@emotion/styled";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Amplify, { API, Auth } from "aws-amplify";
import { graphqlOperation } from "aws-amplify";
import { getCookieValue } from "@ewe-it/ewe-frontend-utils";
import * as mutations from "./graphql/mutations";
import {
  theme,
  GlobalStyle,
  TopNav,
  SubNav,
  MobileMenu,
  Footer,
} from "@ewe-it/ewe-design-react";
import { AddAccountLink } from "./pages/add-account-link.js";
import { DeleteAccountLink } from "./pages/delete-account-link.js";
import { LinkedAccountsList } from "./pages/linked-accounts-list.js";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faTrash,
  faPlusCircle,
  faPlusSquare,
  faLink,
  faUnlink,
} from "@fortawesome/free-solid-svg-icons";

const PageContainer = styled.div`
  position: relative;
  min-height: 100vh;
`;

const ContentWrapper = styled.div`
  padding-bottom: 60px;
  @media (min-width: 768px) {
    padding-bottom: 800px;
  }
`;

const FooterContainer = styled.div`
  width: 100%;
  @media (min-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 0;
  }
`;

Amplify.configure({
  Auth: {
    region: "eu-central-1",
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
  },
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_APPSYNC_ENDPOINT,
  aws_appsync_region: "eu-central-1",
  aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
  aws_appsync_apiKey: "",
});

const oauth = {
  //domain: 'eweauth.auth.eu-central-1.amazoncognito.com',
  domain: process.env.REACT_APP_IDP_DOMAIN,
  scope: ["email", "openid"],
  redirectSignIn: process.env.REACT_APP_REDIRECT_SIGN_IN,
  redirectSignOut: process.env.REACT_APP_REDIRECT_SIGN_OUT,
  responseType: "token",
};

Auth.configure({ oauth });
library.add(faTrash, faPlusCircle, faPlusSquare, faLink, faUnlink);

function useAuth() {
  const [state, setState] = useState({
    authState: "loading",
    authData: null,
    authError: null,
  });

  useEffect(() => {
    async function checkAuth() {
      try {
        const user = await Auth.currentAuthenticatedUser();

        setState({
          authState: "signedIn",
          authData: user,
        });
        setInterval(() => {
          const loginCookie = getCookieValue("loginsession");
          if (!loginCookie || loginCookie === "" || loginCookie === "logout") {
            Auth.signOut();
          }
        }, 1000);
      } catch (error) {
        Auth.federatedSignIn();
      }
    }

    checkAuth();
  }, []);

  return state;
}

function App() {
  const { authState, authData } = useAuth();
  const [mobileMenuIsOpen, setMobileMenuIsOpen] = useState(false);
  const [accountListHasEntries, setAccountListHasEntries] = useState(false);

  function handleLogoutLink() {
    Auth.signOut();
    Auth.signOut({ global: true });
  }

  async function onAddAccountLink(input) {
    const kundennummer = input.input.kundennummer.trim().replace(/ /g, "");
    const vertragsnummer = input.input.vertragsnummer.trim().replace(/ /g, "");
    if (kundennummer.startsWith("2")) {
      const newLink = await API.graphql(
        graphqlOperation(mutations.createKundePowercloudZuordnung, {
          input: {
            kundennummer,
            vertragsnummer,
          },
        }),
      );
      return newLink.kundennummer;
    } else {
      const newLink = await API.graphql(
        graphqlOperation(mutations.createKundeZuordnung, {
          input: {
            kundennummer,
            vertragsnummer,
          },
        }),
      );
      return newLink.kundennummer;
    }
  }

  async function onDeleteAccountLinkPowercloud(kundennummer) {
    await API.graphql(
      graphqlOperation(mutations.deleteKundePowercloudZuordnung, {
        kundennummer,
      }),
    );
  }

  async function onDeleteAccountLinkEasyplus(kundennummer) {
    await API.graphql(
      graphqlOperation(mutations.deleteKundeEasyplusZuordnung, {
        kundennummer,
      }),
    );
  }

  if (authState !== "signedIn") {
    return null;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyle />
      <PageContainer>
        <ContentWrapper>
          <TopNav onBurgerClick={() => setMobileMenuIsOpen(true)} />
          <SubNav
            showMenu={accountListHasEntries}
            username={authData.username}
          />

          <Router>
            <MobileMenu
              onClose={() => setMobileMenuIsOpen(false)}
              isOpen={mobileMenuIsOpen}
              items={
                accountListHasEntries
                  ? [
                      {
                        url: process.env.REACT_APP_CSS_PORTAL,
                        label: "Zur Vertragsübersicht",
                        isExternal: true,
                      },
                    ]
                  : [
                      {
                        url: "/addaccountlink",
                        label: "Kundennummer hinzufügen",
                        isExternal: false,
                      },
                    ]
              }
            ></MobileMenu>
            <Switch>
              <Route
                exact
                path="/"
                render={props => (
                  <LinkedAccountsList
                    accountListHasEntries={accountListHasEntries}
                    onLoaded={setAccountListHasEntries}
                  />
                )}
              />
              <Route
                exact
                path="/addaccountlink"
                render={props => (
                  <AddAccountLink
                    onAddAccountLink={onAddAccountLink}
                    onComponentDidMount={() => setMobileMenuIsOpen(false)}
                  />
                )}
              />
              <Route
                path="/deleteaccountlink/:typ/:kundennummer"
                render={props => (
                  <DeleteAccountLink
                    {...props}
                    onDeleteAccountLinkPowercloud={
                      onDeleteAccountLinkPowercloud
                    }
                    onDeleteAccountLinkEasyplus={onDeleteAccountLinkEasyplus}
                  />
                )}
              />

              <Route
                exact
                path="/logout"
                render={() => {
                  handleLogoutLink();
                  return null;
                }}
              />
            </Switch>
          </Router>
        </ContentWrapper>
        <FooterContainer>
          <Footer backgroundUrl="/footer-back.svg"></Footer>
        </FooterContainer>
      </PageContainer>
    </ThemeProvider>
  );
}

export default App;
