const listeZuordnungen = `
  query listeZuordnungen {
      listeZuordnungen {
        metaId
        kundennummern_easyplus {
          kundennummer
          zuordnungszeitpunkt
        }
        kundennummern_powercloud {
          kundennummer
          zuordnungszeitpunkt
        }
      }
    }`;

export { listeZuordnungen };
