import React from "react";
import styled from "@emotion/styled";
import { Link } from "react-router-dom";
import { Connect } from "aws-amplify-react";
import { graphqlOperation } from "aws-amplify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  GridContainer,
  Box,
  Flex,
  Subline,
  DivTable,
  DivTableRow,
  DivTableColumn,
  ActionButton,
  ErrorContainer,
  IconButton,
} from "@ewe-it/ewe-design-react";

import { contractConnect as cc } from "../data-test-attributes";
import * as queries from "../graphql/queries";
import { formatDate } from "@ewe-it/ewe-frontend-utils";

import { Loading } from "../components/loading";
import SubHeadline from "../components/sub-headline";

const SublineBox = styled(Box)`
  padding: 0 0 40px 0;
`;

const IconButtonBox = styled(Flex)`
  padding: 40px 0 20px 0;
  width: 100%;

  @media (min-width: 768px) {
    justify-content: center;
  }
`;

const IconButtonWrapper = styled(Box)`
  width: 100%;
  @media (min-width: 768px) {
    width: 75%;
  }
`;

const ActionButtonBox = styled(Flex)`
  width: 100%;
  justify-content: flex-end;

  @media (min-width: 768px) {
    justify-content: center;
  }
`;

const ActionButtonWrapper = styled(Flex)`
  width: 100%;
  justify-content: flex-end;

  @media (min-width: 768px) {
    width: 75%;
  }
`;

const SubHeadlineBox = styled(Box)`
  padding: 40px 0 20px 0;
`;

const TableBox = styled(Box)`
  width: 100%;
`;

const TableWrapper = styled(Flex)`
  width: 100%;

  @media (min-width: 768px) {
    justify-content: center;
  }
`;

const DivTableBox = styled(Box)`
  width: 100%;

  @media (min-width: 768px) {
    width: 75%;
  }
`;

function EmptyConnectTable() {
  return (
    <TableWrapper>
      <DivTableBox>
        <DivTable columns={["Nr.", "Kundennummer", "Verknüpft am", ""]}>
          <DivTableRow>
            <DivTableColumn></DivTableColumn>
            <DivTableColumn>
              Bitte fügen Sie ein Kundenkonto hinzu!
            </DivTableColumn>
            <DivTableColumn></DivTableColumn>
            <DivTableColumn></DivTableColumn>
          </DivTableRow>
        </DivTable>
      </DivTableBox>
    </TableWrapper>
  );
}

const ConnectView = ({ data, onLoaded }) => {
  let kundennummern = [];
  if (data) {
    const easyplus = data.kundennummern_easyplus
      ? data.kundennummern_easyplus.map(data => {
          return { ...data, typ: "ep" };
        })
      : [];
    const powercloud = data.kundennummern_powercloud
      ? data.kundennummern_powercloud.map(data => {
          return { ...data, typ: "pc" };
        })
      : [];
    kundennummern = powercloud.concat(easyplus);
  }
  if (kundennummern.length === 0) {
    // setting state in parent component
    onLoaded(false);
    return <EmptyConnectTable />;
  }

  // setting state in parent component
  onLoaded(true);

  return (
    <TableWrapper>
      <DivTableBox>
        <DivTable columns={["Nr.", "Kundennummer", "Verknüpft am", ""]}>
          {kundennummern.map((kundennummer, index) => {
            const counter = ++index;
            return (
              <DivTableRow key={counter}>
                <DivTableColumn>{counter}</DivTableColumn>
                <DivTableColumn>{kundennummer.kundennummer}</DivTableColumn>
                <DivTableColumn>
                  {formatDate(kundennummer.zuordnungszeitpunkt)}
                </DivTableColumn>
                <DivTableColumn>
                  <Link
                    data-test={cc.accountLinkList.deleteLink}
                    to={
                      "/deleteaccountlink/" +
                      kundennummer.typ +
                      "/" +
                      kundennummer.kundennummer
                    }
                    className="link_trash"
                  >
                    <FontAwesomeIcon icon="trash" />
                  </Link>
                </DivTableColumn>
              </DivTableRow>
            );
          })}
        </DivTable>
      </DivTableBox>
    </TableWrapper>
  );
};

export function LinkedAccountsList({ accountListHasEntries, onLoaded }) {
  function handleToPortalClick(e) {
    window.location.assign(process.env.REACT_APP_CSS_PORTAL);
  }

  return (
    <GridContainer>
      <TableBox>
        <SubHeadlineBox>
          <SubHeadline label="Verknüpfung Ihrer Kundendaten mit Ihrem EWE-Login"></SubHeadline>
        </SubHeadlineBox>

        <SublineBox>
          <Subline>
            An dieser Stelle können Sie unkompliziert die Energie-Verträge
            hinzufügen, die zu Ihrer Kundennummer gehören. Diese Verträge können
            Sie online in der Vertragsübersicht einsehen und bearbeiten. Die
            folgenden Kundennummern sind Ihrem Energie-Konto bereits zugeordnet.
          </Subline>
        </SublineBox>

        <Connect query={graphqlOperation(queries.listeZuordnungen)}>
          {({ data, loading, error }) => {
            if (error)
              return (
                <Subline>
                  <ErrorContainer>
                    Es ist ein Fehler aufgetreten! - Bitte versuchen Sie es
                    erneut
                  </ErrorContainer>
                </Subline>
              );
            if (loading) return <Loading loading={true} />;

            return (
              <ConnectView onLoaded={onLoaded} data={data.listeZuordnungen} />
            );
          }}
        </Connect>

        <IconButtonBox>
          <IconButtonWrapper>
            <IconButton
              dataTest={cc.accountLinkList.addLink}
              label="KUNDENNUMMER HINZUFÜGEN"
            />
          </IconButtonWrapper>
        </IconButtonBox>

        {accountListHasEntries && (
          <ActionButtonBox>
            <ActionButtonWrapper>
              <ActionButton
                data-test={cc.accountLinkList.toCss}
                onClick={handleToPortalClick}
                showIcon
              >
                Zur Vertragsübersicht
              </ActionButton>
            </ActionButtonWrapper>
          </ActionButtonBox>
        )}
      </TableBox>
    </GridContainer>
  );
}
