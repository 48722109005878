import React, { useState } from "react";
import styled from "@emotion/styled";
import { Loading } from "../components/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import {
  ColumnContainer,
  SubHeadlineContent,
  SubHeadlineIcon,
  ActionButton,
  Subline,
  ErrorContainer,
  Flex,
  Box,
  GridContainer,
  DialogBox,
} from "@ewe-it/ewe-design-react";
import { contractConnect as cc } from "../data-test-attributes";
import SubHeadline from "../components/sub-headline";

const DivSpacer = styled.div`
  width: 15px;
`;

const Wrapper = styled(Box)`
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 40px;
  }
`;

const ActionButtonContainer = styled(Flex)`
  justify-content: flex-end;
  padding-top: 40px;
`;

const SubHeadlineBox = styled(Box)`
  padding: 0 0 20px 0;
`;

export function DeleteAccountLink({
  match,
  onDeleteAccountLinkPowercloud,
  onDeleteAccountLinkEasyplus,
}) {
  const [toConfirmPage, setToConfirmPage] = useState(false);
  const [loading, setLoading] = useState(false);

  async function handleDeleteAccountLinkClick() {
    try {
      if (match.params.typ === "pc") {
        setLoading(true);
        await onDeleteAccountLinkPowercloud(match.params.kundennummer);
        setLoading(false);
        setToConfirmPage(true);
      } else if (match.params.typ === "ep") {
        setLoading(true);
        await onDeleteAccountLinkEasyplus(match.params.kundennummer);
        setLoading(false);
        setToConfirmPage(true);
      } else {
        setLoading(false);
        // Validierung, Fehlermeldung ausgeben
        return (
          <Wrapper>
            <GridContainer>
              <SubHeadline>
                <SubHeadlineIcon>
                  <FontAwesomeIcon icon="unlink" />
                </SubHeadlineIcon>
                <SubHeadlineContent>
                  <ErrorContainer>
                    Es ist ein Fehler aufgetreten! - Bitte versuchen Sie es
                    erneut
                  </ErrorContainer>
                </SubHeadlineContent>
              </SubHeadline>
              <ColumnContainer>
                <ActionButton
                  data-test={cc.deleteLink.ok}
                  to="/"
                  variant="secondary"
                >
                  Zurück zur Übersicht
                </ActionButton>
              </ColumnContainer>
            </GridContainer>
          </Wrapper>
        );
      }
    } catch (err) {
      console.error(err);
    }
  }
  if (loading) return <Loading loading={loading} />;
  if (toConfirmPage === true) {
    return (
      <Wrapper>
        <GridContainer>
          <DialogBox>
            <SubHeadlineBox>
              <SubHeadline
                label="Verknüpfung zu Vertrag wurde entfernt."
                icon="unlink"
              ></SubHeadline>
            </SubHeadlineBox>

            <Subline>
              Die Verknüpfung Ihres Kundenkontos wurde erfolgreich aufgelöst.
            </Subline>

            <ActionButtonContainer>
              <ActionButton data-test={cc.deleteLink.ok} to="/">
                Fertig
              </ActionButton>
            </ActionButtonContainer>
          </DialogBox>
        </GridContainer>
      </Wrapper>
    );
  }
  return (
    <Wrapper>
      <GridContainer>
        <DialogBox>
          <SubHeadlineBox>
            <SubHeadline
              label="Verknüpfung zu einem Vertrag entfernen"
              icon="unlink"
            ></SubHeadline>
          </SubHeadlineBox>

          <Subline data-test={cc.addLink.confirmmessage}>
            Möchten Sie die Vertragsverknüpfung mit der Kundennummer{" "}
            {match.params.kundennummer} entfernen?
          </Subline>
          <ActionButtonContainer>
            <ActionButton
              data-test={cc.deleteLink.cancel}
              to="/"
              variant="lightgrey"
            >
              Abbrechen
            </ActionButton>
            <DivSpacer />
            <ActionButton
              data-test={cc.deleteLink.delete}
              showIcon
              onClick={handleDeleteAccountLinkClick}
            >
              Löschen
            </ActionButton>
          </ActionButtonContainer>
        </DialogBox>
      </GridContainer>
    </Wrapper>
  );
}
