const createKundeZuordnung = `
    mutation createKundeZuordnung($input: CreateKundeZuordnungInput!) {
        createKundeZuordnung(input: $input) {
            metaId
            kundennummern_easyplus {
                kundennummer
                zuordnungszeitpunkt
            }
            kundennummern_powercloud {
                kundennummer
                zuordnungszeitpunkt
            }
        }
    }`;

const createKundeEasyplusZuordnung = `
    mutation createKundeEasyplusZuordnung($input: CreateKundeEasyplusZuordnungInput!) {
        createKundeEasyplusZuordnung(input: $input) {
            metaId
            kundennummern_easyplus {
                kundennummer
                zuordnungszeitpunkt
            }
            kundennummern_powercloud {
                kundennummer
                zuordnungszeitpunkt
            }
        }
    }`;

const createKundePowercloudZuordnung = `
    mutation createKundePowercloudZuordnung($input: CreateKundePowercloudZuordnungInput!) {
        createKundePowercloudZuordnung(input: $input) {
          metaId
          kundennummern_easyplus {
            kundennummer
            zuordnungszeitpunkt
          }
          kundennummern_powercloud {
            kundennummer
            zuordnungszeitpunkt
          }
        }
    }`;

const deleteKundeEasyplusZuordnung = `
    mutation deleteKundeEasyplusZuordnung($kundennummer: String!) {
        deleteKundeEasyplusZuordnung(kundennummer: $kundennummer) {
            metaId
            kundennummern_easyplus {kundennummer}
            kundennummern_powercloud {kundennummer}
        }
    }`;

const deleteKundePowercloudZuordnung = `
    mutation deleteKundePowercloudZuordnung($kundennummer: String!) {
        deleteKundePowercloudZuordnung(kundennummer: $kundennummer) {
            metaId
            kundennummern_easyplus {kundennummer}
            kundennummern_powercloud {kundennummer}
        }
    }`;

export {
  createKundeZuordnung,
  createKundeEasyplusZuordnung,
  createKundePowercloudZuordnung,
  deleteKundeEasyplusZuordnung,
  deleteKundePowercloudZuordnung,
};
