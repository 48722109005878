import React, { useState, useEffect } from "react";
import styled from "@emotion/styled";
import { Loading } from "../components/loading";

import {
  GridContainer,
  Subline,
  ActionButton,
  DialogBox,
  DialogBoxBody,
  DialogBoxTextInput,
  ErrorContainer,
  Box,
  Flex,
} from "@ewe-it/ewe-design-react";

import { contractConnect as cc } from "../data-test-attributes";
import SubHeadline from "../components/sub-headline";

const DivSpacer = styled.div`
  width: 15px;
`;

const Wrapper = styled(Box)`
  margin-top: 20px;

  @media (min-width: 768px) {
    margin-top: 40px;
  }
`;

const ActionButtonContainer = styled(Flex)`
  justify-content: flex-end;
  padding-top: 20px;
`;

const SublineContainer = styled(Box)`
  padding: 20px 0;
`;

const ErrorBox = styled(Box)`
  padding: 0 0 20px 0;

  @media (min-width: 768px) {
    padding-top: 10px;
  }
`;

const InputContainer = styled(Box)`
  padding-bottom: 16px;
`;

export function AddAccountLink({ onAddAccountLink, onComponentDidMount }) {
  const [formState, setFormState] = useState({
    kundennummer: "",
    vertragsnummer: "",
    vorname: "",
    nachname: "",
  });
  const [toConfirmPage, setToConfirmPage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  useEffect(onComponentDidMount, []);

  function onValueChanged(e) {
    e.preventDefault();
    setErrorMessage("");
    setLoading(false);
    const { name, value } = e.target;
    setFormState(prevState => {
      return { ...prevState, [name]: value };
    });
  }

  async function handleAddAccountLinkClick(e) {
    try {
      let input = {};
      input = {
        kundennummer: formState.kundennummer,
        vertragsnummer: formState.vertragsnummer,
      };

      if (
        !input.kundennummer.match("[0-9 ]+") ||
        !input.vertragsnummer.match("[0-9 ]+")
      ) {
        setErrorMessage(
          "Die eingegeben Daten sind nicht korrekt. Bitte überprüfen Sie die Eingabe.",
        );
      }
      setLoading(true);
      await onAddAccountLink({ input });
      setLoading(false);
      setToConfirmPage(formState.kundennummer.trim().replace(/ /g, ""));
    } catch (err) {
      setLoading(false);
      if (
        err.errors &&
        err.errors[0] &&
        err.errors[0].errorType === "ValidationError"
      ) {
        setErrorMessage(
          "Die eingegeben Daten sind nicht korrekt. Bitte überprüfen Sie die Eingabe.",
        );
      } else if (
        err.errors &&
        err.errors[0] &&
        err.errors[0].errorType === "DuplicateConnectionError" &&
        err.errors[0].path[0] === "createKundeEasyplusZuordnung"
      ) {
        setErrorMessage(
          "Sie können nur ein Kundenkonto verknüpfen, dessen Kundennummer mit der Ziffer 7 beginnt. Eine Verknüpfung haben Sie bereits angelegt.",
        );
      } else if (
        err.errors &&
        err.errors[0] &&
        err.errors[0].errorType === "DuplicateConnectionError" &&
        err.errors[0].path[0] === "createKundePowercloudZuordnung"
      ) {
        setErrorMessage(
          "Sie können nur ein Kundenkonto verknüpfen, dessen Kundennummer mit der Ziffer 2 beginnt. Eine Verknüpfung haben Sie bereits angelegt.",
        );
      } else {
        setErrorMessage(
          "Es ist ein Fehler aufgetreten! - Bitte versuchen Sie es erneut",
        );
      }
      console.error(err);
    }
  }

  if (toConfirmPage !== "") {
    return (
      <Wrapper>
        <GridContainer>
          <DialogBox>
            <SubHeadline label="Verknüpfung hergestellt"></SubHeadline>

            <SublineContainer>
              <Subline data-test={cc.addLink.confirmmessage}>
                Die Verknüpfung zur Kundennummer {formState.kundennummer} wurde
                hergestellt.
              </Subline>
            </SublineContainer>

            <ActionButtonContainer>
              <ActionButton data-test={cc.addLink.ok} to="/" variant="primary">
                Fertig
              </ActionButton>
            </ActionButtonContainer>
          </DialogBox>
        </GridContainer>
      </Wrapper>
    );
  }
  if (loading) return <Loading loading={loading} />;

  return (
    <Wrapper>
      <GridContainer>
        <DialogBox>
          <SubHeadline label="Verknüpfung zu einem Vertrag herstellen"></SubHeadline>

          <SublineContainer>
            <Subline>
              Bitte geben Sie die Nummer des Kontos ein, das mit Ihrem Login
              verknüpft werden soll.
            </Subline>
          </SublineContainer>

          <DialogBoxBody>
            {errorMessage && (
              <ErrorBox>
                <ErrorContainer>{errorMessage}</ErrorContainer>
              </ErrorBox>
            )}
            <InputContainer>
              <DialogBoxTextInput
                data-test={cc.addLink.customerId}
                onChange={onValueChanged}
                value={formState.kundennummer}
                name="kundennummer"
                placeholder="Ihre Kundennummer"
                label="Kundennummer"
              />
            </InputContainer>
            <InputContainer>
              <DialogBoxTextInput
                data-test={cc.addLink.contractId}
                onChange={onValueChanged}
                value={formState.vertragsnummer}
                name="vertragsnummer"
                placeholder="Ihre Vertragsnummer"
                label="Vertragsnummer"
              />
            </InputContainer>
            <ActionButtonContainer>
              <ActionButton
                data-test={cc.addLink.cancel}
                to="/"
                variant="lightgrey"
              >
                Abbrechen
              </ActionButton>
              <DivSpacer></DivSpacer>
              <ActionButton
                data-test={cc.addLink.create}
                showIcon
                onClick={handleAddAccountLinkClick}
              >
                Absenden
              </ActionButton>
            </ActionButtonContainer>
          </DialogBoxBody>
        </DialogBox>
      </GridContainer>
    </Wrapper>
  );
}
